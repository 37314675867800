/**
 * parseDataFromForm
 * @description
 */

export function parseDataFromForm (form) {
  if (!form || !form.elements) {
    throw new Error('Can not parse form: invalid form');
  }

  const elements = Array.from(form.elements);

  const formFields = [];

  elements.forEach(element => {
    const name = element.name;
    const value = element.value;
    if (!name || name.length === 0) return;
    formFields.push({
      name,
      value
    });
  });

  return formFields;
}

/**
 * buildFormFromData
 * @description
 */

export function buildFormFromData (
  data,
  { action, method = 'POST', hidden = true }
) {
  const wrapper = document.createElement('div');
  const form = document.createElement('form');

  form.method = method;
  form.action = action;

  data.forEach(field => {
    const element = document.createElement('input');
    element.name = field.name;
    element.value = field.value;
    form.appendChild(element);
  });

  if (hidden) {
    wrapper.className = 'visually-hidden';
  }

  wrapper.appendChild(form);

  return wrapper;
}
