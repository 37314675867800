import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Form, FormInput, FormRow } from 'fogg/ui';

import { parseDataFromForm, buildFormFromData } from 'lib/form';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Jumbotron from 'components/Jumbotron';

const ContactPage = () => {
  /**
   * handleOnFormSubmit
   * @description
   */

  function handleOnFormSubmit (e) {
    const formData = parseDataFromForm(e.target);

    const formWrapper = buildFormFromData(formData, {
      action:
        'https://element84.us13.list-manage.com/subscribe/post?u=a7a7fcb1ce46c4d001fc76289&amp;id=4a70ef72cf'
    });

    document.body.appendChild(formWrapper);

    formWrapper.querySelector('form').submit();
  }

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-contact' }}>
        <title>Contact</title>
      </Helmet>
      <Jumbotron>
        <h1>Let&apos;s Chat FilmDrop</h1>
      </Jumbotron>
      <Container>
        <FormRow>
          <p>
            Tell us about yourself and if you have the time, a little about your
            work. We&apos;ll get back to you as soon as we can.
          </p>
        </FormRow>

        <Form onSubmit={handleOnFormSubmit}>
          <div className="visually-hidden">
            <FormInput
              name="b_a7a7fcb1ce46c4d001fc76289_4a70ef72cf"
              tabIndex="-1"
            />
          </div>

          <FormRow>
            <FormInput label="Name" name="NAME" required={true} />
          </FormRow>

          <FormRow>
            <FormInput
              label="Email Address"
              type="email"
              name="EMAIL"
              required={true}
            />
          </FormRow>

          <FormRow>
            <FormInput
              label="Have any additional info about your use case?"
              type="text"
              name="COMMENT"
              required={true}
            />
          </FormRow>

          <FormRow>
            <p>
              <Button>Subscribe</Button>
            </p>
          </FormRow>
        </Form>
      </Container>
    </Layout>
  );
};

export default ContactPage;
